@tailwind base;

@tailwind components;

@tailwind utilities;

@import "./variables";
@import "./keyframes";
@import "./transitions";
@import "./forms";

* {
  font-family: $fontFamilyAsistensi !important;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.content {
  @apply min-h-screen;
}

.modal-open {
  @apply overflow-y-hidden;
}

// BUTTONS

.btn {
  @apply text-base rounded-2xl border-0 font-bold relative cursor-pointer flex justify-center items-center;
}

.primary-button {
  @apply text-white bg-gradient-to-r from-orange-500 via-orange-600 to-pink-700 h-14 w-64;
  box-shadow: $primaryShadow;

  &:disabled {
    @apply bg-gradient-to-r from-gray-300 to-gray-300 shadow-none;
  }

  @extend .btn;
  // Transition Effect
  @extend .btnTransition;
  &:hover {
    @extend .btnTransitionHover;
    &::before {
      @extend .btnTransitionHoverBefore;
    }
  }
  &::before {
    @extend .btnTransitionBefore;
  }
}

.secondary-button {
  @apply text-white bg-gradient-to-r bg-purple-500 h-14 w-64;
  box-shadow: $secondaryShadow;

  &:disabled {
    @apply bg-gray-300 shadow-none;
  }

  @extend .btn;
  // Transition Effect
  @extend .btnTransition;
  &:hover {
    @extend .btnTransitionHover;
    background: #16052f;
    &::before {
      @extend .btnTransitionHoverBefore;
    }
  }
  &::before {
    @extend .btnTransitionBefore;
  }
}

.white-btn {
  @apply text-purple-500 bg-white h-14 w-64 font-poppins border border-purple-500;

  @extend .btn;
  // Transition Effect
  @extend .btnTransition;
  &:hover {
    @extend .btnTransitionHover;
    background: #16052f;
    color: #fff;
    &::before {
      @extend .btnTransitionHoverBefore;
    }
  }
  &::before {
    @extend .btnTransitionBefore;
  }
}

.primary-button-outline {
  @apply flex justify-center items-center font-bold text-small tracking-extra text-pink-500 rounded bg-transparent border border-pink-500 cursor-pointer relative h-10 w-52;

  @extend .btnTransition;

  &:hover {
    @extend .btnTransitionHover;
    @apply text-white bg-pink-500;
    &::before {
      @extend .btnTransitionHoverBefore;
    }
  }
  &::before {
    @extend .btnTransitionBefore;
  }
}

.white-button-outline {
  @apply flex justify-center items-center font-bold text-small tracking-extra text-white rounded bg-transparent border border-white cursor-pointer relative h-10 w-48;
  // Transition Effect
  @extend .btnTransition;
  &:hover {
    @extend .btnTransitionHover;
    @apply text-purple-500 bg-white;
    &::before {
      @extend .btnTransitionHoverBefore;
    }
  }
  &::before {
    @extend .btnTransitionBefore;
  }
}

.white-button-nav {
  @apply font-bold text-sm text-purple-500 rounded-2xl bg-white border border-purple-500 cursor-pointer relative h-10 px-4;
  // Transition Effect
  @extend .btnTransition;
  &:hover {
    @extend .btnTransitionHover;
    @apply text-white bg-purple-500;
  }
}

.secondary-button-nav {
  @apply font-bold text-sm text-purple-500 rounded-2xl bg-purple-200 cursor-pointer relative h-10 min-w-28 px-4;
  // Transition Effect
  @extend .btnTransition;
  &:hover {
    @extend .btnTransitionHover;
    @apply text-white bg-purple-500;
  }
}

.primary-button-nav {
  @apply font-bold text-xs text-pink-500 rounded-sm bg-pink-200 cursor-pointer relative h-10 w-24;
  // Transition Effect
  @extend .btnTransition;
  &:hover {
    @extend .btnTransitionHover;
    @apply text-white bg-pink-500;
  }
}

.footer-button {
  @apply text-white px-4 cursor-pointer relative bg-white bg-opacity-16 rounded to-pink-700 h-16;
  // Transition Effect
  @extend .btnTransition;
  &:hover {
    @extend .btnTransitionHover;
    background-color: rgba(255, 255, 255, 0.4);
    &::before {
      @extend .btnTransitionHoverBefore;
    }
  }
  &::before {
    @extend .btnTransitionBefore;
  }
}

.membership-app-button {
  @apply text-white px-4 cursor-pointer relative bg-pink-500 rounded h-14;
  // Transition Effect
  @extend .btnTransition;
  &:hover {
    @extend .btnTransitionHover;
    background-color: rgba(255, 255, 255, 0.4);
  }
}

.btn-home {
  @apply border-0 pt-4 inline-flex text-btn bg-transparent text-gray-800 cursor-pointer font-light;
}

.button-add-beneficiary {
  @apply h-16 py-2 px-8 border border-gray-300 bg-white text-pink-500 tracking-extra text-small font-bold cursor-pointer w-full;
  &:focus,
  &:active {
    outline: none;
  }
}

.button-active-tab {
  @apply w-36 h-10 rounded-sm bg-white font-poppins text-xs font-bold border-0 cursor-pointer mx-1 text-red-600;
  &:focus,
  &:active {
    outline: none;
  }
}

.button-inactive-tab {
  @apply w-36 h-10 rounded-sm bg-red-600 font-poppins text-xs font-bold border-0 cursor-pointer mx-1 text-white;
  &:focus,
  &:active {
    outline: none;
  }
}

// INPUTS

legend {
  display: none !important;
}

.cssLabel {
  color: $lightGrayText !important;
  padding-bottom: 10px !important;
  top: -10px !important;
  left: -12px !important;
  font-size: 15px;
}

.cssLabelError {
  padding-bottom: 10px !important;
  top: -10px !important;
  left: -12px !important;
  font-size: 15px;
  color: $redError !important;
}

.cssLabelSuccess {
  padding-bottom: 10px !important;
  top: -12px !important;
  left: -12px !important;
  font-size: 15px;
  color: $lightGrayText !important;
}

.cssInputSelectQuotation {
  input,
  div div div {
    padding: 10px 28px 10px 14px !important;
  }
}

.defaultPlaceholder {
  div {
    color: #b0b2b4 !important;
  }
}

.checkBox {
  color: $lightGrayText !important;
}

.checkBoxActive {
  color: $primaryColor !important;
  svg {
    transform: scale(1);
  }
  &:checked {
    color: $primaryColor !important;
    background: $primaryColor !important;
  }
}

.checkBoxDisabled {
  color: $lightGrayAsistensi !important;
}

//NEWS STYLES INPUT

.cssInput {
  font-size: 16px !important;
  top: 0 !important;
  border-radius: 4px !important;
  border-color: $lightGrayAsistensi !important;
  font-family: $fontFamilyAsistensi !important;
  div {
    font-size: 16px !important;
  }
}

.cssInputError {
  font-size: 16px !important;
  top: 0 !important;
  border-radius: 4px !important;
  border-color: $redError !important;
  color: $grayFont !important;
  div {
    font-size: 16px !important;
  }

  &::placeholder {
    color: $lightGrayText;
  }

  &:focus,
  &:active {
    border-color: $redError !important;
  }
}

.cssInputSuccess {
  font-size: 16px !important;
  top: 0 !important;
  border-radius: 4px !important;
  border-color: $lightGrayAsistensi !important;
  font-family: $fontFamilyAsistensi !important;
  div {
    font-size: 16px !important;
  }
}

.cssHelperTextError {
  background-color: #fafafa !important;
  margin: 0 !important;
  padding: 8px 12px 0 !important;

  &.background-white {
    background-color: #ffffff !important;
  }
}

// divider
.hr {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
  margin-bottom: 10px;
  &::before {
    content: "";
    background: linear-gradient(to right, transparent, #818078, transparent);
    color: #200844;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &::after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;
    padding: 0 0.5em;
    line-height: 1.5em;
    color: #200844;
    background-color: #fcfcfa;
  }
}

.max-h-20-rem {
  max-height: 25rem !important;
}
// TODO: necessary rule for unique classes promo banner 2022
@media only screen and (min-width: 320px) and (max-width: 374px) {
  .text-responsive-banner {
    bottom: 250px !important;
  }
  .bg-ascend {
    max-width: 53rem !important;
    margin-top: 0.5rem !important;
  }
}
@media (min-width: 360px) {
  .text-responsive-banner {
    bottom: 290px !important;
  }
  .bg-ascend {
    margin-top: -1rem !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 960px) {
  .text-responsive-banner {
    bottom: 640px !important;
  }
  .bg-ascend {
    margin-top: -8.5rem !important;
    max-width: 55rem !important;
  }
}
@media only screen and (min-width: 500px) and (max-width: 600px) {
  .text-responsive-banner {
    bottom: 420px !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 760px) {
  .text-responsive-banner {
    bottom: 540px !important;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .text-responsive-banner {
    bottom: 575px !important;
  }
}

.left-auto {
  @media (min-width: 768px) {
    left: -3rem !important;
  }
  @media (min-width: 1710px) {
    left: -12rem !important;
  }
}
.text-auto-svg {
  @media (min-width: 320px) {
    font-size: 20rem !important;
    top: -3.5rem !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
  }
  @media (min-width: 360px) {
    font-size: 20rem !important;
  }
  @media (min-width: 375px) {
    font-size: 25rem !important;
    top: -6.5rem !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
  }
  @media (min-width: 768px) {
    font-size: 35rem !important;
  }
  @media (min-width: 960px) {
    font-size: 25rem !important;
    top: -8rem !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
  }
  @media (min-width: 1280px) {
    font-size: 20rem !important;
    top: -6.5rem !important;
    left: -3rem !important;
  }
  @media (min-width: 1448px) {
    font-size: 29rem !important;
    top: -10rem !important;
    left: -5rem !important;
  }
  @media (min-width: 1501px) {
    font-size: 33rem !important;
    top: -9rem !important;
    left: -5rem !important;
  }
}

.input-global {
  div.MuiInputBase-formControl {
    font-family: $fontFamilyAsistensi !important;
    @apply rounded text-gray-800 bg-white;
    min-height: 19px;
    svg {
      right: 8px !important;
    }
  }
  &.default {
    div {
      color: #b0b2b4 !important;
    }
  }
}

.wInputFull {
  input:disabled {
    @apply opacity-50;
  }
  @apply w-full bg-transparent;
  @extend .input-global;
  margin: 1.5rem 0.6rem 0.6rem !important;
  &.mt-0 {
    margin: 0.5rem 0.6rem 0.6rem !important;
  }
  &.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.wInputFulCenterText {
  @apply wInputFull;
  margin: 1.5rem 0.6rem 0.6rem !important;
  input {
    text-align: center !important;
    ::placeholder {
      text-align: center !important;
    }
  }
}

.wInputFullNoMargin {
  @apply wInputFull;
  margin-top: 0.5rem !important;
}

.wInputFullNoMarginCenter {
  @apply wInputFull;
  margin-top: 0.5rem !important;
  input {
    text-align: center !important;
    ::placeholder {
      text-align: center;
    }
  }
}

.wInputTwo {
  input:disabled {
    @apply opacity-50;
  }
  @apply w-full bg-transparent;
  @extend .input-global;
  @screen sm {
    width: calc(50% - 1.2rem);
  }
  margin: 1.5rem 0.6rem 0.6rem !important;
  &.mt-0 {
    margin: 0.5rem 0.6rem 0.6rem !important;
  }
  &.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.wInputThree {
  input:disabled {
    @apply opacity-50;
  }
  @apply w-full bg-transparent;
  @extend .input-global;
  @screen sm {
    width: calc(50% - 1.2rem);
  }
  @screen md {
    width: calc((100% / 3) - 1.2rem);
  }
  margin: 1.5rem 0.6rem 0.6rem !important;
  &.mt-0 {
    margin: 0.5rem 0.6rem 0.6rem !important;
  }
  &.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.wInputFour {
  input:disabled {
    @apply opacity-50;
  }
  @apply w-full bg-transparent;
  @extend .input-global;
  @screen sm {
    width: calc(50% - 1.2rem);
  }
  @screen md {
    width: calc((100% / 3) - 1.2rem);
  }
  @screen lg {
    width: calc((100% / 4) - 1.2rem);
  }
  margin: 1.5rem 0.6rem 0.6rem !important;
  &.mt-0 {
    margin: 0.5rem 0.6rem 0.6rem !important;
  }
  &.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.wInputFive {
  input:disabled {
    @apply opacity-50;
  }
  @apply w-full bg-transparent;
  @extend .input-global;
  @screen sm {
    width: calc(50% - 1.2rem);
  }
  @screen md {
    width: calc((100% / 4) - 1.2rem);
  }
  @screen lg {
    width: calc((100% / 5) - 1.2rem);
  }
  margin: 1.5rem 0.6rem 0.6rem !important;
  &.mt-0 {
    margin: 0.5rem 0.6rem 0.6rem !important;
  }
  &.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.cssInputSelect {
  font-size: 16px !important;
  top: 0 !important;
  border-color: $lightGrayAsistensi !important;
  div {
    font-size: 16px !important;
  }
}

// sticky
.resume-sticky {
  position: static;
  top: 10rem;
  @screen md {
    position: sticky;
  }
}

// TABLE
.content-table {
  @apply border border-gray-400 mt-10 overflow-x-auto w-full;
  th.MuiTableCell-head {
    @apply text-gray-800 font-bold text-xs;
  }
  td.MuiTableCell-body {
    @apply text-gray-800 text-xs;
  }
}

// AFFILIATION
.select-plan-cont {
  @apply flex items-center justify-between w-full bg-white border min-h-60p border-gray-300 mt-5 mb-2 rounded-none;
}

// HOME
.bg-steps-home {
  background-image: url(#{$serverUrlImages}/images/path-3.svg);
}

// CHAT BOT
.bg-bot {
  background-image: url(#{$serverUrlImages}/images/enfermera.svg);
}

// ACTION BUTTONS
.action-button {
  @apply w-12 h-12 bg-pink-500 text-white rounded-full;
}

// HEADER
.header-select-country {
  @apply w-40;
  margin: 0 !important;

  & > div {
    height: 2.5rem !important;
  }

  .MuiSelect-select {
    @apply text-sm;
  }
}

// DASHBOARD
.box-dashboard {
  @apply bg-white border border-gray-300 p-5 text-gray-800;
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: url("#{$font-path}/poppins/poppins-v9-latin-300.eot"); /* IE9 Compat Modes */
  src: local("Poppins Light"), local("Poppins-Light"),
    url("#{$font-path}/poppins/poppins-v9-latin-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("#{$font-path}/poppins/poppins-v9-latin-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$font-path}/poppins/poppins-v9-latin-300.woff") format("woff"),
    /* Modern Browsers */ url("#{$font-path}/poppins/poppins-v9-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("#{$font-path}/poppins/poppins-v9-latin-300.svg#Poppins")
      format("svg"); /* Legacy iOS */
}

/* poppins-regular - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("#{$font-path}/poppins/poppins-v9-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Poppins Regular"), local("Poppins-Regular"),
    url("#{$font-path}/poppins/poppins-v9-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("#{$font-path}/poppins/poppins-v9-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$font-path}/poppins/poppins-v9-latin-regular.woff") format("woff"),
    /* Modern Browsers */
      url("#{$font-path}/poppins/poppins-v9-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("#{$font-path}/poppins/poppins-v9-latin-regular.svg#Poppins")
      format("svg"); /* Legacy iOS */
}

/* poppins-600 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("#{$font-path}/poppins/poppins-v9-latin-600.eot"); /* IE9 Compat Modes */
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"),
    url("#{$font-path}/poppins/poppins-v9-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("#{$font-path}/poppins/poppins-v9-latin-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$font-path}/poppins/poppins-v9-latin-600.woff") format("woff"),
    /* Modern Browsers */ url("#{$font-path}/poppins/poppins-v9-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("#{$font-path}/poppins/poppins-v9-latin-600.svg#Poppins")
      format("svg"); /* Legacy iOS */
}

/* poppins-700 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: bold;
	font-weight: 700;
	src: url('#{$font-path}/poppins/poppins-v9-latin-700.eot'); /* IE9 Compat Modes */
	src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
		//  url('#{$font-path}/poppins/poppins-v9-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('#{$font-path}/poppins/poppins-v9-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('#{$font-path}/poppins/poppins-v9-latin-700.woff') format('woff'), /* Modern Browsers */
		 url('#{$font-path}/poppins/poppins-v9-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('#{$font-path}/poppins/poppins-v9-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
  }


/* AvenirNext-regular - latin */
@font-face {
  font-family: "AvenirNext";
  font-style: normal;
  font-weight: 400;
  src: url("#{$font-path}/avenir/AvenirNextLTPro-Regular.eot"); /* IE9 Compat Modes */
  src: local("AvenirNext Regular"), local("AvenirNext-Regular"),
    url("#{$font-path}/avenir/AvenirNextLTPro-Regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("#{$font-path}/avenir/AvenirNextLTPro-Regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$font-path}/avenir/AvenirNextLTPro-Regular.woff") format("woff"),
    /* Modern Browsers */
      url("#{$font-path}/avenir/AvenirNextLTPro-Regular.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("#{$font-path}/avenir/AvenirNextLTPro-Regular.svg#AvenirNext")
      format("svg"); /* Legacy iOS */
}

/* AvenirNext-600 - latin */
@font-face {
  font-family: "AvenirNext";
  font-style: normal;
  font-weight: 600;
  src: url("#{$font-path}/avenir/AvenirNextLTPro-Bold.eot"); /* IE9 Compat Modes */
  src: local("AvenirNext SemiBold"), local("AvenirNext-SemiBold"),
    url("#{$font-path}/avenir/AvenirNextLTPro-Bold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("#{$font-path}/avenir/AvenirNextLTPro-Bold.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$font-path}/avenir/AvenirNextLTPro-Bold.woff") format("woff"),
    /* Modern Browsers */ url("#{$font-path}/avenir/AvenirNextLTPro-Bold.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("#{$font-path}/avenir/AvenirNextLTPro-Bold.svg#AvenirNext")
      format("svg"); /* Legacy iOS */
}

/* abril-fatface-regular - latin */
@font-face {
  font-family: "Abril Fatface";
  font-style: normal;
  font-weight: 400;
  src: url("#{$font-path}/abril/abril-fatface-v12-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("#{$font-path}/abril/abril-fatface-v12-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("#{$font-path}/abril/abril-fatface-v12-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("#{$font-path}/abril/abril-fatface-v12-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("#{$font-path}/abril/abril-fatface-v12-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("#{$font-path}/abril/abril-fatface-v12-latin-regular.svg#AbrilFatface")
      format("svg"); /* Legacy iOS */
}
