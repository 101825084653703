.btnTransition {
	-webkit-transition: background-color 0.5s, color 0.5s;
	transition: background-color 0.5s, color 0.5s;
}

.btnTransitionHover {
	-webkit-transition: background-color 0.1s 0.5s, color 0.1s 0.5s;
	transition: background-color 0.1s 0.5s, color 0.1s 0.5s;
	-webkit-animation: anim-moema-1 0.5s forwards;
	animation: anim-moema-1 0.5s forwards;
}

.btnTransitionBefore {
	content: '';
	position: absolute;
	top: -20px;
	left: -20px;
	bottom: -20px;
	right: -20px;
	background: inherit;
	border-radius: 24px;
	z-index: -1;
	opacity: 0.4;
	-webkit-transform: scale3d(0.8, 0.5, 1);
	transform: scale3d(0.8, 0.5, 1);
}

.btnTransitionHoverBefore {
	-webkit-animation: anim-moema-2 0.3s 0.3s forwards;
	animation: anim-moema-2 0.3s 0.3s forwards;
}
