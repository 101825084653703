// FontFamily asistensi
$fontFamilyAsistensi: 'AvenirNext', 'Open Sans', sans-serif;
$fontPoppins: 'Poppins', 'Open Sans', sans-serif;
$fontAbril: 'Abril Fatface', 'Open Sans', sans-serif;
$font-path: "https://dp6t2sz71u1u6.cloudfront.net/fonts";

// Primary red color
$primaryColor: #fb314a;
$primaryButton: linear-gradient(106deg, #f7553b 0%, #fa4343 53%, #ff2152);
$primaryShadow: 0 10px 16px 0 rgba(251, 49, 74, 0.16);

// Seccondary LightBlue Color
$secondaryColor: #200844;
$secondaryButton: linear-gradient(90deg, rgba(33,170,183,1) 0%, rgba(13,150,163,1) 100%);
$secondaryShadow: 0 10px 16px 0 rgba(32, 8, 68, 0.16);

// DarkGray text
$darkGray: #3c3c3c;
$grayFont: #43484d;
$lightGray: #999;
$lightGrayText: #9aabb5;
$grayLine: #ccc;
$backGray: #eaeaea;
$lightGrayAsistensi: #e6ebf1;
$background: #f4f4f4;

$redError: #e32726;

// LightGray text

// LightGray background
$lightPrimaryColor: #ffe7ea;

$white: #fff;
$black: #000;

$serverUrlImages: 'https://dp6t2sz71u1u6.cloudfront.net';

/* FONT */
$navSize: 12px;
$navLineHeight: 18px;
/* FONT */

/* Media queries */
$xs-max: 599px; // xs max-width
$sm-max: 959px; // sm max-width
$md-max: 1279px; // md max-width
$lg-max: 1919px; // lg max-width
$sm-min: 600px; // sm min-width
$md-min: 960px; // md min-width
$lg-min: 1280px; // lg min-width
$xl-min: 1920px; // xs min-width
$xs: 'only screen and (max-width: 599px)'; // mobiles
$sm: 'only screen and (min-width: 600px) and (max-width: 959px)'; // tablets
$md: 'only screen and (min-width: 960px) and (max-width: 1279px)'; // laptops
$lg: 'only screen and (min-width: 1280px) and (max-width: 1919px)'; //desktop
$xl: 'only screen and (min-width: 1920px) and (max-width: 5000px)'; // widescreen
$lt-sm: 'only screen and (max-width: 599px)';
$lt-md: 'only screen and (max-width: 959px)';
$lt-lg: 'only screen and (max-width: 1279px)';
$lt-xl: 'only screen and (max-width: 1919px)';
$gt-xs: 'only screen and (min-width: 600px)';
$gt-sm: 'only screen and (min-width: 960px)';
$gt-md: 'only screen and (min-width: 1280px)';
$gt-lg: 'only screen and (min-width: 1920px)';
/* Media queries */

:export {
  primaryColor: $primaryColor;
  primaryButton: $primaryButton;
  primaryShadow: $primaryShadow;
  secondaryColor: $secondaryColor;
  secondaryButton: $secondaryButton;
  secondaryShadow: $secondaryShadow;
  grayFont: $grayFont;
  backGray: $backGray;
  lightGray: $lightGray;
  grayLine: $grayLine;
  white: $white;
  black: $black;
  redError: $redError;
}